import React from 'react'
import { Link, useParams } from 'react-router-dom'
import pJS from 'particles.js';
import { ReactTyped } from "react-typed";

function Home() {
  
  const { lang } = useParams();
  const enPhrases = [ " D365 Developer.", " Web Designer.", " Solutions Architect." ];
  const phrases = [ " Desarrollador D365.", " Diseñador Web.", " Arquitecto de MS." ];
  const particlesInit = () => {
    setTimeout(() => {
      pJS("particles", {"particles":{"number":{"value":40,"density":{"enable":true,"value_area":800}},"color":{"value":"#ffffff"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":5},"image":{"src":"img/github.svg","width":100,"height":100}},"opacity":{"value":0.5,"random":false,"anim":{"enable":false,"speed":1,"opacity_min":0.1,"sync":false}},"size":{"value":3,"random":true,"anim":{"enable":false,"speed":40,"size_min":0.1,"sync":false}},"line_linked":{"enable":true,"distance":150,"color":"#ffffff","opacity":0.4,"width":1},"move":{"enable":true,"speed":6,"direction":"none","random":false,"straight":false,"out_mode":"out","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}},"interactivity":{"detect_on":"canvas","events":{"onhover":{"enable":true,"mode":"repulse"},"onclick":{"enable":true,"mode":"push"},"resize":true},"modes":{"grab":{"distance":400,"line_linked":{"opacity":1}},"bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},"repulse":{"distance":200,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true});
    }, 1000);
  }
  if (lang == "en") {
    return (
      <div id="header">
        <div id="particles" onLoad={particlesInit}></div>
        <div className="social-media-links">
          <Link target="_blank" to="https://www.instagram.com/lure_untitled/"><img src="https://www.luretecs.com/images/instagram logo.png" className="social-media" alt="instagram-logo" /></Link>
          <Link target="_blank" to="https://www.linkedin.com/in/lucas-marcelo-contreras/"><img src="https://www.luretecs.com/images/linkedin logo.png" className="social-media" alt="linkedin-logo" /></Link>
          {/* <Link target="_blank" to="#"><img src="https://www.luretecs.com/images/twitter logo.png" classNameName="social-media" alt="twitter-logo" /></Link> */}
        </div>
        <div className="header-content">
          <div className="header-content-box">
            <div className="firstline"><span className="color">Lucas </span>Contreras</div>
            <div className="secondline">
              I'm a&ensp;
              <ReactTyped
                className="color"
                strings={enPhrases}
                typeSpeed={100} // Velocidad de escritura
                backSpeed={70} // Velocidad de borrado
                loop // Loops infinito
              />
            </div>
            <div className="contact">
              <Link to="Mailto:lucastec2002@hotmail.com"><img src="https://www.luretecs.com/images/mail.png" alt="email-pic" className="contactpic" /></Link>
              <Link to="Tel:1150987593"><img src="https://www.luretecs.com/images/call.png" alt="phone-pic" className="contactpic" /></Link>
            </div>
          </div>
        </div>
        <div className="header-image">
          <img src="https://www.luretecs.com/images/man3.png" alt="logo" />
        </div>
      </div>
    )
  }
  else {
    return (
      <div id="header">
        <div id="particles" onLoad={particlesInit}></div>
        <div className="social-media-links">
          <Link target="_blank" to="https://www.instagram.com/lure_untitled/"><img src="https://www.luretecs.com/images/instagram logo.png" className="social-media" alt="instagram-logo" /></Link>
          <Link target="_blank" to="https://www.linkedin.com/in/lucas-marcelo-contreras/"><img src="https://www.luretecs.com/images/linkedin logo.png" className="social-media" alt="linkedin-logo" /></Link>
          {/* <Link target="_blank" to="#"><img src="https://www.luretecs.com/images/twitter logo.png" classNameName="social-media" alt="twitter-logo" /></Link> */}
        </div>
        <div className="header-content">
          <div className="header-content-box">
            <div className="firstline"><span className="color">Lucas </span>Contreras</div>
            <div className="secondline">
              Soy un &ensp;
              <ReactTyped
                className="color"
                strings={phrases}
                typeSpeed={100} // Velocidad de escritura
                backSpeed={70} // Velocidad de borrado
                loop // Loops infinito
              />
            </div>
            <div className="contact">
              <Link to="Mailto:lucastec2002@hotmail.com"><img src="https://www.luretecs.com/images/mail.png" alt="email-pic" className="contactpic" /></Link>
              <Link to="Tel:1150987593"><img src="https://www.luretecs.com/images/call.png" alt="phone-pic" className="contactpic" /></Link>
            </div>
          </div>
        </div>
        <div className="header-image">
          <img src="https://www.luretecs.com/images/man3.png" alt="logo" />
        </div>
      </div>
    )
  }
}

export default Home
