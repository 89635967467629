import React from 'react'

function Footer() {
    return (
        <div className="footer">
            <div className="footer-text">
                ©2024 Lucas Contreras - A LureTecs Page. All rights reserved.
            </div>
        </div>
    )
}

export default Footer