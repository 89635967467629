import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Home from '../screens/Home'
import About from '../screens/About'
import Blog from '../screens/Blog'
import Work from '../screens/Work'
import Contact from '../screens/Contact'

function Navigation() {
  return (
    <BrowserRouter>
        <Navbar />
        <Routes>
            <Route path="/" element={<Navigate to="/es" />} />
            <Route path="/:lang" element={<Home />} />
            <Route path='/:lang/About' element={<About />} />
            <Route path='/:lang/Blog' element={<Blog />} />
            <Route path='/:lang/Work' element={<Work />} />
            <Route path='/:lang/Contact' element={<Contact />} />
        </Routes>
    </BrowserRouter>
  )
}

export default Navigation