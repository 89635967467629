import React from 'react'

function PostBlog({ imgSrc, postDate, postLink, title, text}) {
  return (
    <div className="blogs">
        <a href={postLink ? postLink : "#"}>
            <div className="img">
                <img src={imgSrc} alt="blog-image"/>
                    <div className="blog-date">{postDate}</div>
            </div>
            <div className="blog-text">
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </a>
    </div>
  )
}

export default PostBlog