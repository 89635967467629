import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import Logo from "./Logo"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function Navbar() {
    const { lang } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLanguageChange = (newLang) => {
        // Cambia el idioma en la URL manteniendo la ruta actual
        const currentPath = location.pathname.replace(`/${lang}`, `/${newLang}`);
        navigate(currentPath);
    };

    return (
        <>
            <div id="navigation-content">
            <div className="logo">
                <Logo />
            </div>
            <div className="navigation-close">
                <span className="close-first"></span>
                <span className="close-second"></span>
            </div>
            <div className="navigation-links">
                <NavLink to={"/" + lang + "/"} data-wrap-text="HO ME" data-text="HOME" id="home-link" >HOME</NavLink>
                <NavLink to={"/" + lang + "/About"} data-wrap-text="AB OUT" data-text="ABOUT" id="about-link" >ABOUT</NavLink>
                <NavLink to={"/" + lang + "/Blog"} data-wrap-text="BL OG" data-text="BLOG" id="blog-link" >BLOG</NavLink>
                <NavLink to={"/" + lang + "/Work"} data-wrap-text="WO RK" data-text="WORK" id="portfolio-link" >WORK</NavLink>
                <NavLink to={"/" + lang + "/Contact"} data-wrap-text="CON TA CT" data-text="CONTACT" id="contact-link" >CONTACT</NavLink>
            </div>
            </div>
            <div id="navigation-bar">
                <Logo />
                {/* <div className="btn-group" style={{position: 'absolute',right: '8%',top: '34%'}}>
                    <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        {lang.toUpperCase()}
                    </button>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                        <li><hr className="dropdown-divider"/></li>
                        <li><a className="dropdown-item" href="#">Separated link</a></li>
                    </ul>
                </div>                 <DropdownButton id="dropdown-basic-button" title={lang?.toUpperCase()} style={{position: 'absolute',right: '8%',top: '34%'}}>
                    <Dropdown.Item onSelect={() => handleLanguageChange('es')} href="#0">ES</Dropdown.Item>
                    <Dropdown.Item onSelect={() => handleLanguageChange('en')} href="#0">EN</Dropdown.Item>
                </DropdownButton>*/} 

                <div className="menubar">
                    <span className="first-span"></span>
                    <span className="second-span"></span>
                    <span className="third-span"></span>
                </div>
            </div>
        </>
    )
}

export default Navbar
