import React from 'react'

function PortFolioCard({ className, title, text, imgSrc, projectSrc }) {
    return (
        <div class={"portfolio " + className}>
            <div class="portfolio-image">
                <img src={imgSrc} alt="portfolio-first" />
            </div>
            <div class="portfolio-text">
                <h2>{title}</h2>
                <p>{text}</p>
                <div class="button"><a href={projectSrc ? projectSrc : "#"}><button><span class="index"> View Project<i class="gg-arrow-right"></i></span></button></a></div>
            </div>
        </div>
    )
}

export default PortFolioCard